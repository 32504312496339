import React from 'react'
import Layout from '../components/layout'

import bg from '../images/eat_fit_abq_testimonials.png'

const Contact = () => (
  <Layout>
    <img
      style={{ position: 'absolute', right: 10, top: 10 }}
      src={bg}
      alt="background"
    />
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 40,
      }}
    >
      Testimonials
    </div>
    <div
      style={{
        position: 'absolute',
        fontFamily: 'Verdana, Geneva, sans-serif',
        left: 430,
        top: 90,
        fontSize: 14,
        width: 532,
      }}
    >
      <p>
        "The importance nutrition plays on performance and reaching specific
        body composition is a must for a fitness trainer like myself. Helping my
        clients reach spcific goals here at Turning Point Strength and
        Conditioning is made easier by
        <span className="greentext"> Eat Fit</span> by Nohime. They offer proper
        nutrition and make it effortless. You’ll definitely want to check out
        <span className="greentext"> Eat Fit</span> by Nohime!"
        <br />
        <b>
          <i>~ Steven Horwarth - Performance Coach</i>
        </b>
      </p>
    </div>
    <div
      style={{
        position: 'absolute',
        fontFamily: 'Verdana, Geneva, sans-serif',
        left: 430,
        top: 260,
        fontSize: 14,
        width: 532,
      }}
    >
      <p>
        "Being a professional fighter for over 11 years, with a very buy
        schedule, <span className="greentext"> Eat Fit</span> by Nohime creates
        healthy, great tasting food, delivered right to my door. No more diets
        that didn’t last long, I can concentrate on my training, get home and
        have a delicious, healthy meal waiting for me. No more bland chicken,
        <span className="greentext"> Eat Fit</span> prides itself in a variety
        of meats and vegetables, all prepared differently each week. As far as a
        great diet plan - it’s been the best decision of my career."
        <br />
        <b>
          <i>~ Holly Holm - UFC Bantamweight Champion</i>
        </b>
      </p>
    </div>
    <div
      style={{
        position: 'absolute',
        fontFamily: 'Verdana, Geneva, sans-serif',
        left: 430,
        top: 430,
        fontSize: 14,
        width: 532,
      }}
    >
      <p>
        "Keeping on the cutting edge of my diet has been made so much easier
        with <span className="greentext"> Eat Fit</span> by Nohime. No more lost
        time and super expensive meals I needed to prepare for myself. The work
        is all done for me, so I concentrate on my fitness practice, my business
        and my teaching. <span className="greentext">Eat Fit </span>
        serves up healthy affordable meals and they are delivered to my house -
        just heat, eat and I’m ready to go!" <br />
        <b>
          <i>
            ~ Jodie Esquibel - Professional Athlete, Business Owner And <br />
            Yoga Instructor
          </i>
        </b>
      </p>
    </div>
    <div
      style={{
        position: 'absolute',
        fontFamily: 'Verdana, Geneva, sans-serif',
        left: 430,
        top: 600,
        fontSize: 14,
        width: 532,
      }}
    >
      <p>
        "I use Eat Fit ABQ every time I'm in fight camp! No matter how hard I
        train or how much cardio I do if my diet isn't on point wouldn't to be
        able to make the weight. Training 3 to 4 times a day, being a mother, a
        wife and a business owner has left me with almost no time to take care
        of my nutrition. I have the utmost trust in <span className="greentext">Eat Fit</span> for handling all
        my needs. I am always so amazed at my transformation whenever I am
        Eating Nohine's meals and tracking my macros. Don't take my word for it,
        try it for yourself and you won't be disappointed!"
        <br />
        <b>
          <i>~ Michelle "Karate Hottie" Waterson</i>
        </b>
      </p>
    </div>
  </Layout>
)

export default Contact
